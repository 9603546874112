import { Component, OnInit, ɵtemplateRefExtractor, ChangeDetectorRef } from '@angular/core';
import { AuthenticationService } from '.././authentication.service'
import { Router } from '@angular/router';
import {NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {

  errorStatus: number
  users
  usersList
  newUserObjects =[]
  closeResult: string;
  emptyError: boolean
  passwordError: boolean
  ltlMarginPct: any
  ltlMarginSum: any
  drayageMarginPct: any
  drayageMarginSum: any
  deleteEmail: string
  readOnly = true
  toggleEditButtonText = 'Unlock'
  success: boolean
  successDelete: boolean
  successEdit: boolean
  editEmail
  editCompanyName
  editPhoneNumber
  editDrayageMarginPct
  editDrayageMarginSum
  editLTLMarginPct
  editLTLMarginSum
  LTLMarginType
  drayageMarginType
  ammount
  ltlAmmount
  drayageAmmount
  marginTypes
  saveError
  serverError
  loading: boolean
  modalOption: NgbModalOptions = {backdrop:'static',
  keyboard: false};
    
  constructor(private authenticatorService: AuthenticationService, private router: Router, private modalService: NgbModal,  private changeDetector: ChangeDetectorRef) { }

  logout(){
    localStorage.clear();
    this.router.navigate(['/login'])
  }
  
  toggleEditButton() {
    this.readOnly = !this.readOnly
    if (this.readOnly == true){
      this.toggleEditButtonText = 'Unlock'
    } else{
      this.toggleEditButtonText = 'Lock'
    }
  }

  resetToggleButton() {
    this.readOnly = true
    this.toggleEditButtonText = 'Unlock'
  }

  open(content) {
    this.resetErrors()
    this.modalService.open(content, this.modalOption);
  }

  openDeleteModal(delete_content, email){
    this.resetErrors()
    this.deleteEmail = email
    this.modalService.open(delete_content, this.modalOption)
  }

  openEditModal(edit_content, email, companyName, phoneNumber, drayageSum, drayagePct, tlSum, tlPct) {
    this.resetErrors()
    this.resetToggleButton()
    this.editEmail = email
    this.editCompanyName = companyName
    this.editPhoneNumber = phoneNumber
    this.editLTLMarginPct = tlPct
    this.editLTLMarginSum = tlSum
    this.editDrayageMarginPct = drayagePct
    this.editDrayageMarginSum = drayageSum
    this.marginTypes = ["Margin Percent", "Margin Sum"];
    if (this.editLTLMarginPct === null){
      this.LTLMarginType = 'Margin Sum'
      this.ltlAmmount = this.editLTLMarginSum
    } else if (this.editLTLMarginSum == null) {
      this.LTLMarginType = 'Margin Percent'
      this.ltlAmmount = this.editLTLMarginPct
    }
    if (this.editDrayageMarginPct == null){
      this.drayageMarginType = 'Margin Sum'
      this.drayageAmmount = this.editDrayageMarginSum
    } else if (this.editDrayageMarginSum == null) {
      this.drayageMarginType = 'Margin Percent'
      this.drayageAmmount = this.editDrayageMarginPct
    }
    this.modalService.open(edit_content, this.modalOption)
  }

  editUser(email, companyName, phoneNumber, ltlMarginType, ltlAmmount, drayageMarginType, drayageAmmount){
    if (this.isEmpty(email, "p", "p", companyName, phoneNumber, ltlMarginType, ltlAmmount, drayageMarginType, drayageAmmount)) {
      this.emptyError = true;
      return;
    }
    if (ltlMarginType=='Margin Percent'){
      this.ltlMarginPct = ltlAmmount
      this.ltlMarginSum = null
    } else {
      this.ltlMarginPct = null
      this.ltlMarginSum = ltlAmmount
    } 
    if (drayageMarginType=='Margin Percent'){
      this.drayageMarginPct = drayageAmmount
      this.drayageMarginSum = null
    } else {
      this.drayageMarginPct = null
      this.drayageMarginSum = drayageAmmount
    }
    this.loading = true
    this.authenticatorService.editUser(email, companyName, phoneNumber, this.ltlMarginPct, this.ltlMarginSum, this.drayageMarginPct, this.drayageMarginSum).subscribe(res=>{
      this.loading = false
      this.successEdit = true
      this.modalService.dismissAll()
      this.getUsers()
      this.changeDetector.markForCheck()
      this.changeDetector.detectChanges();
    }, error => {
      this.loading = false
      if (error.status == 400){
        this.saveError = true
      } else if (error.status == 401 || error.status == 500){
        this.router.navigate(['login']);
      } else {
        this.serverError = true
      }
    })
  }

  resetErrors() {
    this.serverError = false
    this.saveError = false
    this.emptyError = false
    this.passwordError = false
    this.success = false
    this.successDelete = false
    this.successEdit = false
    this.LTLMarginType = ''
    this.drayageMarginType = ''
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  //function for add user
  save(email, password, retypePassword, companyName, phoneNumber, ltlMarginType, ltlAmmount, drayageMarginType, drayageAmmount, userType) {
    this.resetErrors()
    if (userType == 'User') {
      userType = 'user'
    }
    if (userType == 'International Agent') {
      userType = 'international-agent'
    }
    if (userType == 'Internal User') {
      userType = 'internal-user'
    }
    console.log(userType)
    if (this.isEmpty(email, password, retypePassword, companyName, phoneNumber, ltlMarginType, ltlAmmount, drayageMarginType, drayageAmmount)) {
      this.emptyError = true;
      return;
    }
    if (!this.checkPasswordMatch(password, retypePassword)) {
      this.passwordError = true;
      return;
    }
    if (ltlMarginType=='Margin Percent'){
      this.ltlMarginPct = ltlAmmount
      this.ltlMarginSum = null
    } else {
      this.ltlMarginPct = null
      this.ltlMarginSum = ltlAmmount
    }
    if (drayageMarginType=='Margin Percent'){
      this.drayageMarginPct = drayageAmmount
      this.drayageMarginSum = null
    } else {
      this.drayageMarginPct = null
      this.drayageMarginSum = drayageAmmount
    }
    //Send Post to Server
    this.loading = true
    this.authenticatorService.addUser(email, password, companyName, phoneNumber, this.ltlMarginPct, this.ltlMarginSum, this.drayageMarginPct, this.drayageMarginSum, userType).subscribe(res =>{
      this.loading = false
      this.success = true
      this.modalService.dismissAll()
      this.getUsers()
      this.changeDetector.markForCheck()
      this.changeDetector.detectChanges();

      return;
    }, error => {
      this.loading = false
      if (error.status == 400){
        this.saveError = true
      } else if (error.status == 401 || error.status == 500){
        this.router.navigate(['login']);
      } else {
        this.serverError = true
      }
    })
  }

  checkRole(role){
    if(role == 'user'){
      role = 'Domestic User'
    }
    if(role == 'international-agent'){
      role = 'International Agent'
    }
    if(role == 'internal-user'){
      role = 'Internal User'
    }
    return role
  }

  checkPasswordMatch(password, retypePassword) {
    if (password != retypePassword) {
      return false
    }
    return true
  }

  isEmpty(email, password, retypePassword, companyName, phoneNumber, ltlMarginType, ltlAmmount, drayageMarginType, drayageAmmount) {
    if(email == '' || password == '' || retypePassword == '' || companyName == '' || phoneNumber == '' || ltlMarginType == '' || ltlAmmount == '' || drayageMarginType == '' || drayageAmmount == '' ) {
      return true;
    }
    return false
  }

  deleteUser(email) {
    this.loading = true
    //Authservice to delete user
    this.authenticatorService.deleteUser(email).subscribe(res=>{
      this.successDelete = true
      this.getUsers()
      this.changeDetector.markForCheck()
      this.changeDetector.detectChanges();
      this.loading = false
    }, error => {
      this.loading = false
      if (error.status == 400){
        this.saveError = true
      } else if (error.status == 401 || error.status == 500){
        this.router.navigate(['login']);
      } else {
        this.serverError = true
      }
    })
  }

  getUsers() {
     //Send request to server
     this.authenticatorService.getUsers().subscribe(res => {
      this.users=JSON.stringify(res)
      this.usersList=JSON.parse(this.users)
      this.newUserObjects.length = 0
      this.usersList.forEach(element => {
        this.newUserObjects.push((element))
        
      });
      this.changeDetector.detectChanges();
      this.newUserObjects=[...this.newUserObjects]
    }, error => {
      this.errorStatus = error.status
      if (error.status == 401 || error.status == 500){
        this.router.navigate(['login']);
      }
      else {
        this.router.navigate(['login']);
      }
    })
  }

  ngOnInit() { 
    this.getUsers()
  }

}
