import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private search_url = 'https://ltl.cargostar.io/lambda/quoteSearch'
  private url = 'https://lvrpn82rra.execute-api.us-east-2.amazonaws.com/prod'
  httpOptions
  constructor(private http: HttpClient) {}

  setHeader(token, email, role) {
    try {
      localStorage.setItem("key", JSON.stringify(token))
      localStorage.setItem("email", JSON.stringify(email))
      localStorage.setItem("role", JSON.stringify(role))
    } catch (e){
      console.error('Error saving to localStorage', e);
    }
  }

  contact(email, phoneNum, companyName, message){
    if (phoneNum == '' && companyName=='' ){
      return this.http.post(this.url + "/contact", {email: email, message: message})
    } else {
      return this.http.post(this.url + "/contact", {email: email, phoneNum: phoneNum, companyName: companyName, message: message})
    }
  }

  forgotPassword(email){
    return this.http.post(this.url + "/password", { email: email})
  }

  getQuote(moveType, originZip, destinationZip, accessorials, dims, unitType, tsaBoolean, totalWeight, destCity, destState, originCity, originState) {
    try {
      var token = JSON.parse(localStorage.getItem("key"))
      var email = JSON.parse(localStorage.getItem("email"))
      var role = JSON.parse(localStorage.getItem("role"))
      var headers_object = new HttpHeaders().set("Authorization", token);
      this.httpOptions = {
        headers: headers_object
      };
    } catch (e) {
      console.error('Error getting data from localStorage', e);
    } 
    return this.http.post(this.search_url, {email: email, role: role, originZipCode: originZip, destZipCode: destinationZip, moveType: moveType, dims: dims,
      unitType, accessorials: accessorials, tsaApprovedOnly: tsaBoolean, totalWeight: totalWeight, destCity: destCity, destState: destState, originCity: originCity, originState: originState}, this.httpOptions, )
  }
  confirmQuote(originZipCode, destZipCode, moveType, dims, unitType, accessorials, carrierName, carrierSCAC, carrierCode, serviceLevel, total, transitDays, quoteNumber, buyPrice, pickAddress, contactPerson, contactNumber, locationHours, destAddress, contactPersonDest, contactNumberDest, locationHoursDest, dCompany, pCompany, brokerSource){
    try {
      var token = JSON.parse(localStorage.getItem("key"))
      var email = JSON.parse(localStorage.getItem("email"))
      var headers_object = new HttpHeaders().set("Authorization", token);
      this.httpOptions = {
        headers: headers_object
      };
    } catch (e) {
      console.error('Error getting data from localStorage', e);
    } 
    return this.http.post(this.url + "/quote/confirm", {email: email, originZipCode: originZipCode, destZipCode: destZipCode, moveType: moveType, 
      dims: dims, accessorials: accessorials, 
      CarrierName: carrierName, TransitDays: transitDays,
      Total: total, QuoteNumber: quoteNumber, CarrierSCAC: carrierSCAC, CarrierCode: carrierCode, ServiceLevel: serviceLevel, buyPrice: buyPrice,
      unitType: unitType, brokerSource: brokerSource, pickupInfo: { address: pickAddress, contactName: contactPerson, contactPhoneNum: contactNumber, locationHours: locationHours, companyName: pCompany}, 
      destInfo: { address: destAddress, contactName: contactPersonDest, contactPhoneNum: contactNumberDest, locationHours: locationHoursDest, companyName: dCompany}}, this.httpOptions, )
  }

  deleteUser(email) {
    try {
      var token = JSON.parse(localStorage.getItem("key"))
      var headers_object = new HttpHeaders().set("Authorization", token);
      this.httpOptions = {
        headers: headers_object, 
        body: {email: email }
      };
    } catch (e) {
      console.error('Error getting data from localStorage', e);
    } 
    return this.http.request('delete', this.url + "/users", this.httpOptions )
  }

  editUser(email, companyName, phoneNumber, ltlMarginPct, ltlMarginSum, drayageMarginPct, drayageMarginSum) {
    try {
      var token = JSON.parse(localStorage.getItem("key"))
      var headers_object = new HttpHeaders().set("Authorization", token);
      this.httpOptions = {
        headers: headers_object
      };
    } catch (e) {
      console.error('Error getting data from localStorage', e);
    } 
    return this.http.post(this.url + "/users/edit", {email: email,
      companyName: companyName, phoneNum: phoneNumber, margins: { drayagePct: drayageMarginPct, drayageSum: drayageMarginSum, tlPct: ltlMarginPct, tlSum: ltlMarginSum} }, this.httpOptions, )
  }

  addUser(email, password, companyName, phoneNumber,ltlMarginPct, ltlMarginSum, drayageMarginPct, drayageMarginSum, userType) {
    try {
      var token = JSON.parse(localStorage.getItem("key"))
      var headers_object = new HttpHeaders().set("Authorization", token);
      this.httpOptions = {
        headers: headers_object
      };
    } catch (e) {
      console.error('Error getting data from localStorage', e);
    } 
    return this.http.put(this.url + "/users", {email: email, password: password, companyName: companyName, 
      phoneNum: phoneNumber, margins: { drayagePct: drayageMarginPct, drayageSum: drayageMarginSum, tlPct: ltlMarginPct, tlSum: ltlMarginSum}, role: userType}, this.httpOptions)
  }

  getUsers() {
    try {
      console.log("In get users")
      var token = JSON.parse(localStorage.getItem("key"))
      var headers_object = new HttpHeaders().set("Authorization", token);
      this.httpOptions = {
        headers: headers_object
      };
    } catch (e) {
      console.error('Error getting data from localStorage', e);
    }
    return this.http.get(this.url + "/users", this.httpOptions)
  }

  login(email: string, password: string): Observable<any> {
    return this.http.post(this.url + "/login", { email: email, password: password })

  }

  submitRequestAccount(email: string, company: string, phone: string): Observable<any> {
    return this.http.post(this.url + "/users", { email: email, companyName: company, phoneNum: phone})
  }
}
