import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ShipriteService {

  private url = 'https://ltl.cargostar.io/lambda/quoteSearchShipRite'
  httpOptions
  
  constructor(private http: HttpClient) { }


  getQuote(moveType, originZip, destinationZip, accessorials, dims, unitType, tsaBoolean, totalWeight, destCity, destState, originCity, originState) {
    try {
      var email = JSON.parse(localStorage.getItem("email"))
      var token = JSON.parse(localStorage.getItem("key"))
      var role = JSON.parse(localStorage.getItem("role"))
      var headers_object = new HttpHeaders().set("Authorization", token);
      this.httpOptions = {
        headers: headers_object
      };
    } catch (e) {
      console.error('Error getting data from localStorage', e);
    } 
    return this.http.post(this.url, {email: email, role: role, originZipCode: originZip, destZipCode: destinationZip, moveType: moveType, dims: dims,
        unitType, accessorials: accessorials, tsaApprovedOnly: tsaBoolean, totalWeight: totalWeight, destCity: destCity, destState: destState, originCity: originCity, originState: originState}, this.httpOptions)
  
  }
}
