import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { timer } from 'rxjs';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  sucessMessage: boolean
  errorMessage: boolean
  doesntExistMessage: boolean
  loading: boolean
  emptyMessage: boolean
  constructor(private router: Router, private authenticator: AuthenticationService) { }

  reset(){
    this.emptyMessage=false
    this.sucessMessage=false
    this.doesntExistMessage=false
  }

  emptyCheck(email){
    if (!email){
      return true
    }
    return false
  }

  forgotPassword(email){
    this.reset()
    if (this.emptyCheck(email)){
      this.emptyMessage=true
      return
    }
    this.loading=true
    this.authenticator.forgotPassword(email).subscribe(res => {
      this.loading=false
      this.sucessMessage = true
      const refreshTimer = timer(3000)
      refreshTimer.subscribe(val => {
        this.router.navigate(['login']);
      });
      
    }, error => {
      this.loading=false
      if (error.status == '400' ){
        this.doesntExistMessage=true
      } else {
        this.errorMessage = true
      }
    });
  }

  ngOnInit() {
  }

}
