import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatProgressSpinnerModule } from '@angular/material';

import { AuthenticationService } from './authentication.service';
import { RequestAccountComponent } from './request-account/request-account.component';
import { LoginComponent } from './login/login.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { QuotePageComponent } from './quote-page/quote-page.component';
import { AppHttpInterceptor } from './interceptor/httpconfig.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatAutocompleteModule,MatInputModule} from '@angular/material';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ScrollingModule, ScrollDispatcher} from '@angular/cdk/scrolling';
import { SearchPipe } from './search.pipe';
import { ScrolltodirectiveDirective } from './scrolltodirective.directive';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { TabDirective } from './tab-directive.directive';
import { NumericOnlyDirective } from './numeric-only.directive';

const appRoutes: Routes = [
  { path: 'request-account', component: RequestAccountComponent },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: AdminDashboardComponent },
  { path: '', component: QuotePageComponent },
  { path: 'confirmation', component: ConfirmationComponent },
  { path: 'forgot', component: ForgotPasswordComponent},
  { path: 'contact', component: ContactUsComponent}
];

@NgModule({
  declarations: [
    AppComponent,
    RequestAccountComponent,
    LoginComponent,
    AdminDashboardComponent,
    QuotePageComponent,
    SearchPipe,
    ScrolltodirectiveDirective,
    ConfirmationComponent,
    ForgotPasswordComponent,
    ContactUsComponent,
    TabDirective,
    NumericOnlyDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    NgbModule,
    FormsModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
    ScrollingModule
  ],
  providers: [
    AuthenticationService,
    ScrollDispatcher//,
    /*{
      provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true
    }*/
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
