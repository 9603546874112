import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {

  constructor() { }
  private message;

  getMessage(){
    return this.message
  }

  updateMessage(message){
    console.log("In confirm service: " + JSON.stringify(message))
    this.message = message
  }
}
