import { Directive, AfterViewInit, ElementRef } from '@angular/core';

@Directive({
  selector: '[scrollTo]'
})
export class ScrolltodirectiveDirective implements AfterViewInit{
  constructor(private elRef:ElementRef) { }
  ngAfterViewInit(): void {
    this.elRef.nativeElement.scrollIntoView();
  }

}
