import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  emailPresent: boolean
  errorMessage: boolean
  sucessMessage: boolean
  loading: boolean
  email: String
  lockButton: Boolean

  constructor(private router: Router, private authenticator: AuthenticationService) { }

  logout(){
    localStorage.clear();
    this.router.navigate(['/login'])
  }

  submit(email, phoneNum, companyName, message){
    this.lockButton=true
    this.loading = true
    if (this.emailPresent){
      this.authenticator.contact(this.email, '', '', message).subscribe(res => {
        this.loading=false
        this.sucessMessage = true
        this.lockButton=false
      }, error => {
        this.loading=false
        this.errorMessage=true
        this.lockButton=false
      })
    } else {
      this.authenticator.contact(email, phoneNum, companyName, message).subscribe(res => {
        this.loading=false
        this.sucessMessage = true
        this.lockButton=false
      }, error => {
        this.loading=false
        this.errorMessage = true
        this.lockButton=false
      })
    }
  }

  ngOnInit() {
    try {
      var email = JSON.parse(localStorage.getItem("email"))
    } catch (e) {
      console.error('Error getting data from localStorage', e);
    } 
    if (email){
      this.emailPresent=true
      this.email = email
    } else {
      this.emailPresent=false
    }
  }

}
