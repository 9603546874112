import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '.././authentication.service'
import { MatProgressSpinnerModule } from '@angular/material';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [ AuthenticationService ]
})
export class LoginComponent implements OnInit {

  title = 'Sign In';
  subTitle = "TMS";
  body = ' ';
  apiResponse = {}
  isEmpty: boolean
  errorStatus: number
  loading: boolean
  
  constructor(private authenticatorService: AuthenticationService, private router: Router){}


  forgotAccount(){
    this.router.navigate(['/forgot']);
  }

  Signin= function (email: string, password:string ) {
    this.isEmpty = this.checkIfEmpty(email, password)
    if(this.isEmpty){
      return;
    }
    this.loading = true
    //Send Request to server
    this.authenticatorService.login(email, password).subscribe(res => {
      if (res.token && res.role) {
        if (res.role == 'admin') {
          this.authenticatorService.setHeader(res.token, email, (res.role).toLowerCase())
          this.segueToAdmin()
        }
        else {
          this.authenticatorService.setHeader(res.token, email, (res.role).toLowerCase())
          this.segueToQuote()
        }
      } else {
        var errorMessage = "Oops Something went wrong with your request"
      }
    }, error => {
      this.loading = false
      this.errorStatus = error.status
      if (error.status==404){
        var errorMessage = "Invalid Credentials"
      }
      else {
        console.log("something is wrong with downstream backend")
      }
    })
  };

  checkIfEmpty(email: string, password: string): boolean {
    if(email == '' || password == ''){
      return true;
    }
    return false
  }

  segueToAdmin() {
    //route to Admin Dashboard
    this.router.navigate(['/dashboard']);
  }

  segueToQuote() {
    //route to Quote Dashboard
    this.router.navigate(['']);
  }

  RequestAccount= function () {
    //route to Request Account Page
    this.router.navigate(['/request-account']);
  };
  ngOnInit() {
    
  }

  ngOnDestroy(){
    this.loading = false;
  }

}
