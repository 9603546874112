import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { timer } from 'rxjs';


@Component({
  selector: 'app-request-account',
  templateUrl: './request-account.component.html',
  styleUrls: ['./request-account.component.css']
})
export class RequestAccountComponent implements OnInit {
  body: string
  isEmpty: false
  message = 'Please fill out all fields'
  success: boolean
  errorStatus: number
  loading: boolean

  constructor(private authenticatorService: AuthenticationService, private router: Router){}

  submit= function (email: string, company: string, phone: string ) {
    this.isEmpty = this.checkIfEmpty(email, company, phone)
    if (this.isEmpty){
      return;
    }
    //Send Request to server
    this.loading = true
    
    this.authenticatorService.submitRequestAccount(email, company, phone).subscribe(res => {
      this.loading = false
      this.body = res.body; 
      if (res.message == 'Email sent'){
        this.success = true
        const refreshTimer = timer(3000)
        refreshTimer.subscribe(val => {
         this.router.navigate(['login']);
        });
      }
    }, error => {
      this.loading = false
      this.errorStatus = error.status
    })
  };

  checkIfEmpty(email: string, company: string, phone: string): boolean{
    if(email == '' || company == '' || phone == ''){
      return true
    }
    return false
  }

  ngOnInit() {
  }

  ngOnDestroy(){
    this.loading = false
  }

}
