import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from '../confirmation.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {

  confirmationDetails
  dims
  unitType="lb"
  metricType='cm'

  constructor(private router: Router, private confirm: ConfirmationService) { }

  navigateToQuoteDashboard(){
    this.router.navigate(['']);
  }

  ngOnInit() {
    this.confirmationDetails = this.confirm.getMessage()
    if (!this.confirmationDetails){
      this.router.navigate([''])
    } else {
      this.dims = this.confirmationDetails.CargoDesc
      console.log(this.dims)
      if (this.confirmationDetails.unitType == "US"){
        this.unitType = 'lb'
        this.metricType = 'in'
      } else {
        this.unitType = 'kg'
        this.metricType = 'cm'
      }
    }
  }
}
