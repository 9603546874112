import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild, HostListener } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { GltService } from '../glt.service'
import { ExfreightService } from '../exfreight.service'
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import zipcodes from '../../assets/zips.json';
import { map, startWith, catchError } from 'rxjs/operators';
import {forkJoin, of} from 'rxjs';
import { ConfirmationService } from '../confirmation.service';
import { FormControl, FormGroup, FormArray, FormBuilder } from '@angular/forms';
import _ from 'lodash';
import { error } from 'protractor';
import { ShipriteService } from '../shiprite.service';

@Component({
  selector: 'app-quote-page',
  templateUrl: './quote-page.component.html',
  styleUrls: ['./quote-page.component.css'],
  styles:[`
  .star {
    position: relative;
    display: inline-block;
    font-size: 1rem;
    color: #d3d3d3;
  }
  .full {
    color:#6c757d

  }
  .half {
    position: absolute;
    display: inline-block;
    overflow: hidden;
    color: #6c757d
  }
`],
  changeDetection: ChangeDetectionStrategy.Default
})
export class QuotePageComponent implements OnInit {
  form: FormGroup;
  emptyFields: boolean
  liftGateIsChecked = false
  hazmatIsChecked = false
  allQuotes=[]
  loading: boolean
  QuoteNumber
  CarrierName
  TransitDays
  Total
  CarrierSCAC
  CarrierCode
  RateRemarks
  ServiceLevel
  Breakdown
  remarksList =[]
  breakdownList = []
  bookOk
  bookError: boolean
  myControl = new FormControl();
  myDControl = new FormControl();
  options = zipcodes
  originFilteredOptions;
  destinationFilteredOptions;
  weightError: boolean
  drayageSelected: boolean
  allQuotesPopulated = false
  allQuotesCarrierSorted
  buyPrice
  isTsaApproved
  totalWeight
  totalVolume
  originZipCode 
  destZipCode 
  moveType='LTL'
  unitType 
  accessorials
  dimUnitType = 'in'
  bookingloading
  noResults
  dimWeightType = 'lb'
  resultDims=[]
  tsaIsChecked= false
  over4000=false
  length1: string
  width1: string
  height1: string
  weight1: string
  qty: string 
  dZip: string
  oZip: String
  emptyAddressError: boolean
  modalOption: NgbModalOptions = {};
  status504=false;
  originLiftGateIsChecked=false;
  destinationLiftGateIsChecked=false;
  residentialDelivery=false;
  residentialPickup=false;
  airportDelivery=false;
  airportPickup=false;
  containerDestination=false;
  containerOrigin=false;
  pkgType = 'Pallet'
  dims=[]
  creds: FormArray
  metricType = 'US'
  isAgent: boolean
  brokerSource: String
  quotesReceived: boolean
  oZipForView
  dZipForView
  itemClass
  selectedZip
  checkedOClick = false

  constructor(private authenticatorService: AuthenticationService, private gltService: GltService, private shiprite: ShipriteService, private exfreightService: ExfreightService, private confirm: ConfirmationService, private router: Router, private modalService: NgbModal,  private changeDetector: ChangeDetectorRef, private fb: FormBuilder) { 
    this.form = this.fb.group({

      Qty: "", 
      DimType: "Pallet", 
      Weight: "", 
      Length: "", 
      Width: "", 
      Height: "", 
      Hazmat: "", 
      credentials: this.fb.array([]),
    });

    this.creds = this.form.controls.credentials as FormArray;
  }
  scroll(el) {
    this.changeDetector.detectChanges();
    setTimeout(() => {
      el.scrollIntoView();
    }, 1000);
}

addCreds() {
  this.creds.push(this.fb.group({
    Qty: "", 
    DimType: "Pallet", 
    Weight: "", 
    Length: "", 
    Width: "", 
    Height: "", 
    Hazmat: "", 
  }));
}

check(){
  if (this.oZip  != '' && this.dZip != '' && this.length1 && this.width1 && this.height1 && this.weight1 && this.qty && this.qty){
    console.log(this.oZip)
    console.log(this.dZip)
    this.length1=undefined
    this.width1=undefined
    this.height1=undefined
    this.weight1=undefined
    this.qty=undefined
    this.pkgType = 'Pallet'
  }
  else{
    this.emptyFields = true
  }
}

openConfirmModal(book_content, email){
  this.modalOption.backdrop = 'static';
  this.modalOption.keyboard = false;
  this.modalService.open(book_content, this.modalOption)

} 

logout(){
  localStorage.clear();
  this.router.navigate(['/login'])
}

openContact(contact_content){
  this.modalService.open(contact_content)

}

remove(index){
  this.creds.removeAt(index)
}
  removeItem(index){
    if (this.dims.length <= 1){
      this.dims.splice(index+1, 1);
    }
    else {
      this.dims.splice(index, 1);
    }
    
  }

  calculateTotal(){
    this.dims
  }

  sortCarrierName(){
    this.allQuotes = [...this.allQuotes.sort((a, b) => a.CarrierName.localeCompare(b.CarrierName))]
  }

  sortTransitDays(){
    this.allQuotes = [...this.allQuotes.sort((a, b) => a.TransitDays.localeCompare(b.TransitDays))]    
  }

  sortTotalPrice(){
    this.allQuotes = [...this.allQuotes.sort((a, b) => a.Total.localeCompare(b.Total))]    
  }

  resetGlobalVariables() {
    this.liftGateIsChecked = false
    this.hazmatIsChecked = false
  }

  itemUnitType(value){
    if (value == 'US'){
      this.dimUnitType = 'in'
      this.dimWeightType = 'lb'
      this.metricType = value
    } else {
      this.dimUnitType = 'cm'
      this.dimWeightType = 'kg'
      this.metricType = value
    }
  }

  drayageCheck(searchValue : string ) {  
    if (searchValue == "Drayage"){
      this.drayageSelected=true
    } else {
      this.drayageSelected=false
    }
  }

  clear(){
    // this.form.reset();
    this.dZip = ''
    this.oZip = ''
    this.originLiftGateIsChecked=false;
    this.destinationLiftGateIsChecked=false;
    this.residentialDelivery=false;
    this.residentialPickup=false;
    this.airportDelivery=false;
    this.airportPickup=false;
    this.containerDestination=false;
    this.containerOrigin=false;
    this.tsaIsChecked= false
    this.form = this.fb.group({

      Qty: "", 
      DimType: "Pallet", 
      Weight: "", 
      Length: "", 
      Width: "", 
      Height: "", 
      Hazmat: "", 
      credentials: this.fb.array([]),
    });

    this.creds = this.form.controls.credentials as FormArray;
    this.creds.reset()
    this.itemUnitType('US')
    
  }

  reset(){
    this.allQuotes.length = 0;
    this.emptyFields=false
    this.remarksList.length = 0
    this.breakdownList.length = 0
    this.weightError=false
    this.allQuotesPopulated=false
    this.noResults = false
    this.over4000 = false
    this.resultDims.length = 0
    this.status504=false
    this.changeDetector.detectChanges();
    this.quotesReceived = false
    this.itemClass = 0

  }

  addressEmpty(pickAddress, contactPerson, contactNumber, locationHours, destAddress, contactPersonDest, contactNumberDest, locationHoursDest, dCompany, pCompany){
    if (pickAddress== '' ||  contactPerson== '' || contactNumber=='' || locationHours=='' || destAddress=='' || contactPersonDest=='' || contactNumberDest=='' || locationHoursDest=='' || dCompany== '' || pCompany==''){
      this.emptyAddressError = true
      return true;
    } else {
      return false;
    }
  }

  book(bookedQuote_content, carrierName, transitDays, total, quoteNumber, carrierSCAC, carrierCode, serviceLevel, buyPrice, pickAddress, contactPerson, contactNumber, locationHours, destAddress, contactPersonDest , contactNumberDest , locationHoursDest, dCompany, pCompany){
    this.bookingloading=true
    if ( this.addressEmpty(pickAddress, contactPerson, contactNumber, locationHours, destAddress, contactPersonDest, contactNumberDest, locationHoursDest, dCompany, pCompany)) {
      return
    }
   this.authenticatorService.confirmQuote(this.originZipCode, this.destZipCode, this.moveType, this.dims, this.unitType, this.accessorials, carrierName, carrierSCAC, carrierCode, serviceLevel, total, transitDays, quoteNumber, this.buyPrice, pickAddress, contactPerson, contactNumber, locationHours, destAddress, contactPersonDest, contactNumberDest, locationHoursDest, dCompany, pCompany, this.brokerSource).subscribe(res =>{
      this.loading=false;
      this.modalService.dismissAll()
      var confirmationDetails = {
        OriginZip: this.originZipCode,
        DestZip: this.destZipCode,
        CarrierName: carrierName,
        ConfirmationNo: quoteNumber,
        MoveType: this.moveType,
        TransitDays: transitDays,
        Rate: total,
        CargoDesc: this.resultDims,
        unitType: this.unitType
      }
      this.emptyAddressError=false
      this.confirm.updateMessage(confirmationDetails)
      this.router.navigate(['/confirmation']);
    }, error => {
      console.log(error)
      this.bookingloading=false;
      this.bookError=true;
    })
  }

  bookSuccessful(){
    this.bookOk = true
    this.reset()
    this.allQuotes.length = 0
    this.dims.length= 0
    this.allQuotesPopulated = false
    this.bookingloading=true
    this.modalService.dismissAll()
  }

  close(){
    console.log("close success alert")
    this.bookOk=false;
  }

  calculateCBMCBF(){
    var length = 0
    var width = 0
    var height =0
    var volume = 0
    var vol = 0
    var listOfVol = []
    var listOfWeights = []
    var totalPieces =[]
    var pageDims = [];
    pageDims.push(this.form.value);
    this.form.value.credentials.forEach(element => {
      pageDims.push(element);
    });
    pageDims.forEach(element =>{
      if (element.Qty == '' || element.DimType == '' || element.Weight == '' || element.Length == '' || element.Width== '' || element.Height == ''){
        return
      }
      length = parseFloat(element.Length)
      width = parseFloat(element.Width)
      height = parseFloat(element.Height)
      vol = (length * width * height) * element.Qty
      listOfVol.push(vol)
      listOfWeights.push((parseFloat(element.Weight) * parseInt(element.Qty)))
      totalPieces.push(parseInt(element.Qty))
    })
    volume = listOfVol.reduce((a, b) => a + b, 0)
    var totalWeight = listOfWeights.reduce((a, b) => a + b, 0)
    if(Number.isNaN(totalWeight)){
      totalWeight = 0;
    }
    if(Number.isNaN(totalPcs)){
      totalPcs = 0;
    }
    if(Number.isNaN(volume)){
      volume = 0;
    }
    var totalPcs = totalPieces.reduce((a, b) => a + b, 0)
    this.totalWeight = totalWeight
    /*
      length (inches) x width (inches) x height (inches) / 61,023.8 = cubic meters
      length(inches) × width(inches) × height(inches) ÷ 1728 = cubic feet(cf³)
      length(cm) × width(cm) × height(cm) ÷ 28316.846592 = cubic feet(cf³)
      length (cm) x width (cm) x height (cm) / 1,000,000 = cubic meters
    */
    if (this. dimUnitType == 'in') {
      return `${totalPcs} pcs.  |   ${(volume/1728).toFixed(2)} cbf.  |   ${(volume/61023.8).toFixed(2)} cbm.  |    ${totalWeight} lbs.`    
    } else {
      return `${totalPcs} pcs.  |   ${(volume/28316.846592).toFixed(2)} cbf.  |   ${(volume/1000000).toFixed(2)} cbm.  |    ${totalWeight} kgs.`
    }
  }

  calculateCubicFeet(length, width, height, quantity){
    console.log("Calculating cubic ft for L: %s, W: %s, H: %s, Qty: %s", length.replace(/\D/g,''), width.replace(/\D/g,''), height.replace(/\D/g,''), quantity.replace(/\D/g,''));
    var totalCubicInches
    var totalCubicFeet
    var divisor
    if (this. dimUnitType == 'in') {
      divisor = 1728;
    } else {
      length = length /  2.54
      width = width /  2.54
      height = height /  2.54
      divisor = 1728;
    }
    totalCubicInches = parseFloat(length) * parseFloat(width) * parseFloat(height) * parseFloat(quantity);
    totalCubicFeet = totalCubicInches / divisor;
    return totalCubicFeet;
}

  calculateItemClass(density){
    if(density < 1){
        return 500;
    }
    else if(density < 2){
        return 400;
    }
    else if(density < 3){
        return 300; 
    }
    else if(density < 4){
        return 250;
    }
    else if(density < 5){
        return 200;
    }
    else if(density < 6){
        return 175;
    }
    else if(density < 7){
        return 150;
    }
    else if(density < 8){
        return 125;
    }
    else if(density < 9){
        return 110;
    }
    else if(density < 10.5){
        return 100;
    }
    else if(density < 12){
        return 92.5;
    }
    else if(density < 13.5){
        return 85;
    }
    else if(density < 15){
        return 77.5;
    }
    else if(density < 22.5){
        return 70;
    }
    else if(density < 30){
        return 65;
    }
    else if(density < 35){
        return 60;
    }
    else if(density < 50){
        return 55;
    }
    else{
        return 50;
    }
}

  trackByFn(index: any, item: any) {
    return index;
 }
 
 strip(str) {
  return str.replace(/^\s+|\s+$/g, '');
}

  openQuoteModal(quote_content, carrierName, transitDays, total, quoteNumber, carrierSCAC, carrierCode, rateRemarks, serviceLevel, breakdown, buyPrice, isTsaApproved, brokerSource){
    this.brokerSource = brokerSource
    this.QuoteNumber = quoteNumber
    this.CarrierName = carrierName
    this.TransitDays = transitDays
    this.Total = total
    this.CarrierSCAC = carrierSCAC
    this.CarrierCode = carrierCode
    this.buyPrice = buyPrice
    this.isTsaApproved = isTsaApproved
    var remarks = rateRemarks
    this.RateRemarks  = remarks
    this.ServiceLevel = serviceLevel
    var itemBreakdown = breakdown.Item
    this.breakdownList.length = 0
    itemBreakdown.forEach(element => {
      this.breakdownList.push(element)
    });
    this.Breakdown = JSON.stringify(breakdown)
    this.modalService.open(quote_content, { size: 'lg', centered: true })
  }

  openBookedModal(bookedQuote_content){
    this.modalService.open(bookedQuote_content, { size: 'lg', centered: true }).result.then((result) => {
      console.log(result);
      this.bookSuccessful()
    }, (reason) => {
      (reason);
      this.bookSuccessful()
    });
  }

  newSubmit(moveType, originZip, destinationZip,){
    this.reset()
    
    if (originZip == '' || destinationZip == ''){
      this.emptyFields = true
      return
    }

    var originAddress = originZip.substring(6,originZip.length)
    var destinationAddress = destinationZip.substring(6,destinationZip.length)

    this.oZipForView = originZip
    this.dZipForView = destinationZip

    var originArray = originAddress.split(",")
    var destinationArray = destinationAddress.split(",")

    var originCity = originArray[0]
    var originState = originArray[1]

    var destinationCity = destinationArray[0]
    var destinationState = destinationArray[1]
  
    originZip = originZip.substring(0,5)
    destinationZip = destinationZip.substring(0,5)
    var accessorials = [];
    if (this.originLiftGateIsChecked == true) {
      accessorials.push("LFO")
    } 
    if (this.destinationLiftGateIsChecked == true) {
      accessorials.push("LFD")
    }
    if (this.residentialDelivery == true) {
      accessorials.push("RSD")
    } 
    if (this.residentialPickup == true) {
      accessorials.push("RSO")
    }
    if (this.airportDelivery == true) {
      accessorials.push("ARD")
    } 
    if (this.airportPickup == true) {
      accessorials.push("ARO")
    }
    if (this.containerDestination == true) {
      accessorials.push("CTD")
    } 
    if (this.containerOrigin == true) {
      accessorials.push("CTO")
    } 
    console.log(accessorials)

    this.dims.length = 0

    this.dims.push({Qty: this.strip(this.form.value.Qty).replace(/\D/g,''), DimType: this.strip(this.form.value.DimType), Weight: this.strip(this.form.value.Weight).replace(/\D/g,''), Length: this.strip(this.form.value.Length).replace(/\D/g,''), Width: this.strip(this.form.value.Width).replace(/\D/g,''), Height: this.strip(this.form.value.Height).replace(/\D/g,''), Hazmat: this.form.value.Hazmat})
    console.log(this.form.value.Hazmat)
    this.form.value.credentials.forEach(element => {
      console.log(element.Hazmat)
      this.dims.push({Qty: this.strip(element.Qty).replace(/\D/g,''), DimType: this.strip(element.DimType), Weight: this.strip(element.Weight).replace(/\D/g,''), Length: this.strip(element.Length).replace(/\D/g,''), Width: this.strip(element.Width).replace(/\D/g,''), Height: this.strip(element.Height).replace(/\D/g,''), Hazmat: element.Hazmat})
    });

    this.dims.forEach(element => {
      if (element.Qty == '' || element.DimType == '' || element.Weight == '' || element.Length == '' || element.Width== '' || element.Height == ''){
        this.emptyFields = true
        return
      }
      if (parseInt(element.Weight) > 40000) {
        this.weightError = true
        return
      }
      });
    if (this.emptyFields || this.weightError){
      return
    }
    //Convert to dim format and do packageType replace stuff
    this.dims.forEach(element => {
    if (element.DimType == 'Pallet'){
      element.DimType = 'PLT'
    } else if (element.DimType == 'Carton'){
      element.DimType = 'CTN'
    } else if (element.DimType == 'Crate'){
      element.DimType = 'CRT'
    } else if (element.DimType == 'Drum'){
      element.DimType = 'DRM'
    } else {
      element.DimType = 'OTH'
    }
    if (element.Hazmat == ''){
      element.Hazmat = false
    }
    
    });
    //Set total weight
    var totalWeightPounds;
    if(this. dimUnitType == 'in'){
      totalWeightPounds = this.totalWeight;
    }
    else{
      totalWeightPounds = this.totalWeight * 2.20462;
    }
    //Calculate total cubic ft
    var totalCubicFt = 0;
    this.dims.forEach((dim) => {
      if (dim.Hazmat == true ){
        dim.HazmatFriendly = 'yes'
      } else {
        dim.HazmatFriendly = 'no'
      } 
      this.resultDims.push(dim)
      totalCubicFt += this.calculateCubicFeet(dim.Length, dim.Width,  dim.Height, dim.Qty)
    })
    console.log(this.dims.length)
    console.log(totalCubicFt)
    this.itemClass = this.calculateItemClass(totalWeightPounds/totalCubicFt)
    this.loading = true
    // this.exfreightService.getQuote(moveType, originZip, destinationZip, accessorials, this.dims, this.metricType, this.tsaIsChecked, this.totalWeight, destinationCity, destinationState, originCity, originState).subscribe( res => {
    //   console.log(res)
    //   var quotes=JSON.stringify(res)
    //   var quotesList=JSON.parse(quotes)
    //   // quotesList = []
    //   quotesList.forEach(element => {
    //     this.allQuotes.push((element))
    //     this.originZipCode = this.strip(originZip)
    //     this.destZipCode = this.strip(destinationZip)
    //     this.moveType = moveType
    //     this.unitType = this.metricType
    //     this.accessorials = accessorials
    //   });
    //   this.changeDetector.detectChanges();
    //   if (this.allQuotes.length > 0){
    //     this.allQuotesPopulated = true
    //     this.loading=false;
    //   }
    //  })
    
    
    
    let orch = this.authenticatorService.getQuote(moveType, originZip, destinationZip, accessorials, this.dims, this.metricType, this.tsaIsChecked, this.totalWeight, destinationCity, destinationState, originCity, originState).pipe(
      catchError(err => of(undefined)),
    );
    let exfreight = this.exfreightService.getQuote(moveType, originZip, destinationZip, accessorials, this.dims, this.metricType, this.tsaIsChecked, this.totalWeight, destinationCity, destinationState, originCity, originState).pipe(
      catchError(err => of(undefined)),
    );
    let glt = this.gltService.getQuote(moveType, originZip, destinationZip, accessorials, this.dims, this.metricType, this.tsaIsChecked, this.totalWeight, destinationCity, destinationState, originCity, originState).pipe(
      catchError(err => of(undefined)),
    );
    let shiprite = this.shiprite.getQuote(moveType, originZip, destinationZip, accessorials, this.dims, this.metricType, this.tsaIsChecked, this.totalWeight, destinationCity, destinationState, originCity, originState).pipe(
      catchError(err => of(undefined)),
    );

    
    forkJoin([exfreight]).subscribe(results => {
      console.log("ExFreight Fork" + results[0])

       //502 or Err
       if (results[0] == undefined){
        console.log("ExFreight ERR")
        return
      }
      //Empty
      if (results[0].length==0){
        console.log("ExFreight Empty")
        return 
      }

      if (this.quotesReceived == true && this.allQuotesPopulated == true && this.loading == false && this.noResults == true){
        return 
      }
 
      this.allQuotesPopulated = true
      this.loading=false;
      var updatedQuotes = []
      var quotes=JSON.stringify(results[0])
      var quotesList=JSON.parse(quotes)
      console.log(this.allQuotes)
      // quotesList = []
      // console.log(quotesList)
      quotesList.forEach(element => {
        //console.log(element)
        updatedQuotes.push((element))
        this.originZipCode = this.strip(originZip)
        this.destZipCode = this.strip(destinationZip)
        this.moveType = moveType
        this.unitType = this.metricType
        this.accessorials = accessorials
      });
      updatedQuotes.push(...this.allQuotes)

      updatedQuotes.sort((a, b) => (a.buyPrice - b.buyPrice))
      // this.allQuotesPopulated = true
      this.allQuotes = _.uniqWith(updatedQuotes, (a, b) =>
      a.CarrierSCAC === b.CarrierSCAC &&
      a.TransitDays === b.TransitDays &&
      a.ServiceLevel === b.ServiceLevel)
      // Limit results to 20
      if (this.allQuotes.length > 20){
        this.allQuotes.length = 20
      }
      console.log(this.allQuotes)
      // if (this.allQuotes.length == 0){
      //   this.allQuotesPopulated = true
      //   this.noResults = true;
      // }

    });

    forkJoin([shiprite]).subscribe(results => {
      console.log("ShipRite Fork" + results[0])

       //502 or Err
       if (results[0] == undefined){
        console.log("ShipRite ERR")
        return
      }
      //Empty
      if (results[0].length==0){
        console.log("ShipRite Empty")
        return 
      }

      if (this.quotesReceived == true && this.allQuotesPopulated == true && this.loading == false && this.noResults == true){
        return 
      }
 
      this.allQuotesPopulated = true
      this.loading=false;
      var updatedQuotes = []
      var quotes=JSON.stringify(results[0])
      var quotesList=JSON.parse(quotes)
      console.log(this.allQuotes)
      // quotesList = []
      // console.log(quotesList)
      quotesList.forEach(element => {
        //console.log(element)
        updatedQuotes.push((element))
        this.originZipCode = this.strip(originZip)
        this.destZipCode = this.strip(destinationZip)
        this.moveType = moveType
        this.unitType = this.metricType
        this.accessorials = accessorials
      });
      updatedQuotes.push(...this.allQuotes)

      updatedQuotes.sort((a, b) => (a.buyPrice - b.buyPrice))
      // this.allQuotesPopulated = true
      this.allQuotes = _.uniqWith(updatedQuotes, (a, b) =>
      a.CarrierSCAC === b.CarrierSCAC &&
      a.TransitDays === b.TransitDays &&
      a.ServiceLevel === b.ServiceLevel)
      // Limit results to 20
      if (this.allQuotes.length > 20){
        this.allQuotes.length = 20
      }
      console.log(this.allQuotes)
      // if (this.allQuotes.length == 0){
      //   this.allQuotesPopulated = true
      //   this.noResults = true;
      // }

    });

    forkJoin([orch]).subscribe(results => {
      console.log("LFS Fork" + results[0])

       //502 or Err
       if (results[0] == undefined){
        console.log("LFS ERR")
        return
      }
      //Empty
      if (results[0].length==0){
        console.log("LFS Empty")
        return 
      }

      if (this.quotesReceived == true && this.allQuotesPopulated == true && this.loading == false && this.noResults == true){
        return 
      }
 
      //this.quotesReceived = true
      this.loading=false;
      this.changeDetector.detectChanges();
      var updatedQuotes = []
      var quotes=JSON.stringify(results[0])
      var quotesList=JSON.parse(quotes)
      console.log(this.allQuotes)
      // quotesList = []
      // console.log(quotesList)
      quotesList.forEach(element => {
        //console.log(element)
        updatedQuotes.push((element))
        this.originZipCode = this.strip(originZip)
        this.destZipCode = this.strip(destinationZip)
        this.moveType = moveType
        this.unitType = this.metricType
        this.accessorials = accessorials
      });
      updatedQuotes.push(...this.allQuotes)

      updatedQuotes.sort((a, b) => (a.buyPrice - b.buyPrice))
      this.allQuotesPopulated = true
      this.loading=false;
      //this.quotesReceived = true
      // this.allQuotesPopulated = true
      this.allQuotes = _.uniqWith(updatedQuotes, (a, b) =>
      a.CarrierSCAC === b.CarrierSCAC &&
      a.TransitDays === b.TransitDays &&
      a.ServiceLevel === b.ServiceLevel)
      // Limit results to 20
      if (this.allQuotes.length > 20){
        this.allQuotes.length = 20
      }
      // if (this.allQuotes.length == 0){
      //   this.allQuotesPopulated = true
      //   this.noResults = true;
      // }

    });

    forkJoin([glt]).subscribe(results => {
      console.log("GLT Fork" + results[0])
      //502 or Err
      if (results[0] == undefined){
        console.log("GLT ERR")
        return
      }
      //Empty
      if (results[0].length==0){
        console.log("GLT Empty")
        return 
      }
      if (this.quotesReceived == true && this.allQuotesPopulated == true && this.loading == false && this.noResults == true){
        return 
      }
      var updatedQuotes = []
      var quotes=JSON.stringify(results[0])
      var quotesList=JSON.parse(quotes)
      console.log(this.allQuotes)
      quotesList.forEach(element => {
        updatedQuotes.push((element))
        this.originZipCode = this.strip(originZip)
        this.destZipCode = this.strip(destinationZip)
        this.moveType = moveType
        this.unitType = this.metricType
        this.accessorials = accessorials
      });
      updatedQuotes.push(...this.allQuotes)

      updatedQuotes.sort((a, b) => (a.buyPrice - b.buyPrice))
      this.loading=false;
      this.allQuotesPopulated = true
      //this.quotesReceived = true
      this.changeDetector.detectChanges();
      // this.allQuotesPopulated = true
      this.allQuotes = _.uniqWith(updatedQuotes, (a, b) =>
      a.CarrierSCAC === b.CarrierSCAC &&
      a.TransitDays === b.TransitDays &&
      a.ServiceLevel === b.ServiceLevel)
      // Limit results to 20
      if (this.allQuotes.length > 20){
        this.allQuotes.length = 20
      }
      // if (this.allQuotes.length == 0){
      //   this.allQuotesPopulated = true
      //   this.noResults = true;
      // }

    });



    forkJoin([orch, glt, exfreight, shiprite]).subscribe(results => {
      console.log("FINAL Processing")
      console.log(results[0])
      console.log(results[1])
      console.log(results[2])
      console.log(results[3])
      //All Err Out
      if (results[0] == undefined && results[1] == undefined && results[2] == undefined && results[3] == undefined){
        this.quotesReceived = true
        this.loading=false;
        this.status504=true
        return
      }

      if (results[1].length==0 && results[0].length==0 && results[2].length==0 && results[3].length==0){
        this.quotesReceived = true
        this.allQuotesPopulated = true
        this.loading=false;
        this.noResults = true;
        return 
      }
      // if (results[1] == undefined && this.allQuotes.length==0){
      //   this.quotesReceived = true
      //   this.allQuotesPopulated = true
      //   this.loading=false;
      //   this.noResults = true;
      //   return 
      // }
      //All Empty
      if ((results[1] == undefined || results[1].length==0) && (results[0] == undefined || results[0].length==0 ) && results[2].length > 0){
        console.log("no lfs no glty yes ex")
        var updatedQuotes = []
        var quotes=JSON.stringify(results[2])
        var quotesList=JSON.parse(quotes)
        console.log(this.allQuotes)
        quotesList.forEach(element => {
          updatedQuotes.push((element))
          this.originZipCode = this.strip(originZip)
          this.destZipCode = this.strip(destinationZip)
          this.moveType = moveType
          this.unitType = this.metricType
          this.accessorials = accessorials
        });
        updatedQuotes.push(...this.allQuotes)
  
        updatedQuotes.sort((a, b) => (a.buyPrice - b.buyPrice))
        this.loading=false;
        this.quotesReceived = true
        this.allQuotesPopulated = true
        this.allQuotes = _.uniqWith(updatedQuotes, (a, b) =>
        a.CarrierSCAC === b.CarrierSCAC &&
        a.TransitDays === b.TransitDays &&
        a.ServiceLevel === b.ServiceLevel)
        // Limit results to 20
        if (this.allQuotes.length > 20){
          this.allQuotes.length = 20
        }
        return 
      }
      if ((results[0].length > 0 ) && (results[1] == undefined || results[1].length==0 ) && (results[2] == undefined || results[2].length == 0 )){
        console.log("yes lfs no glty no ex")
        var updatedQuotes = []
        var quotes=JSON.stringify(results[0])
        var quotesList=JSON.parse(quotes)
        console.log(quotesList)
        console.log(this.allQuotes)
        quotesList.forEach(element => {
          updatedQuotes.push((element))
          this.originZipCode = this.strip(originZip)
          this.destZipCode = this.strip(destinationZip)
          this.moveType = moveType
          this.unitType = this.metricType
          this.accessorials = accessorials
        });
        updatedQuotes.push(...this.allQuotes)
  
        updatedQuotes.sort((a, b) => (a.buyPrice - b.buyPrice))
        this.loading=false;
        this.quotesReceived = true
        this.allQuotesPopulated = true
        this.allQuotes = _.uniqWith(updatedQuotes, (a, b) =>
        a.CarrierSCAC === b.CarrierSCAC &&
        a.TransitDays === b.TransitDays &&
        a.ServiceLevel === b.ServiceLevel)
        // Limit results to 20
        if (this.allQuotes.length > 20){
          this.allQuotes.length = 20
        }
        console.log(this.allQuotes)
        return 
      }

      if ((results[0] == undefined || results[0].length == 0 ) && (results[1].length > 0) && (results[2] == undefined || results[2].length == 0)){
        console.log("no lfs yes glty no ex")
        var updatedQuotes = []
        var quotes=JSON.stringify(results[1])
        var quotesList=JSON.parse(quotes)
        console.log(quotesList)
        console.log(this.allQuotes)
        quotesList.forEach(element => {
          updatedQuotes.push((element))
          this.originZipCode = this.strip(originZip)
          this.destZipCode = this.strip(destinationZip)
          this.moveType = moveType
          this.unitType = this.metricType
          this.accessorials = accessorials
        });
        updatedQuotes.push(...this.allQuotes)
  
        updatedQuotes.sort((a, b) => (a.buyPrice - b.buyPrice))
        this.loading=false;
        this.quotesReceived = true
        this.allQuotesPopulated = true
        this.allQuotes = _.uniqWith(updatedQuotes, (a, b) =>
        a.CarrierSCAC === b.CarrierSCAC &&
        a.TransitDays === b.TransitDays &&
        a.ServiceLevel === b.ServiceLevel)
        // Limit results to 20
        if (this.allQuotes.length > 20){
          this.allQuotes.length = 20
        }
        console.log(this.allQuotes)
        return 
      }

      if (results[0] && this.allQuotes.length==0){
        console.log("timed out")
        var quotes=JSON.stringify(results[0])
        var quotesList=JSON.parse(quotes)
        // quotesList = []
        console.log(quotesList)
        quotesList.forEach(element => {
          this.allQuotes.push((element))
          this.originZipCode = this.strip(originZip)
          this.destZipCode = this.strip(destinationZip)
          this.moveType = moveType
          this.unitType = this.metricType
          this.accessorials = accessorials
        });
        console.log(quotesList)
        console.log(this.allQuotes)

      }
      if (results[1] == undefined) {
        this.quotesReceived = true
        return
      }
      // var updatedQuotes = []
      // var quotes=JSON.stringify(results[1])
      // var quotesList=JSON.parse(quotes)
      // console.log(this.allQuotes)
      // // quotesList = []
      // // console.log(quotesList)
      // quotesList.forEach(element => {
      //   //console.log(element)
      //   updatedQuotes.push((element))
      //   this.originZipCode = this.strip(originZip)
      //   this.destZipCode = this.strip(destinationZip)
      //   this.moveType = moveType
      //   this.unitType = this.metricType
      //   this.accessorials = accessorials
      // });

      // updatedQuotes.push(...this.allQuotes)

      // updatedQuotes.sort((a, b) => (a.buyPrice - b.buyPrice))
      // this.loading=false;
      // this.quotesReceived = true
      // this.allQuotesPopulated = true
      // this.allQuotes = _.uniqWith(updatedQuotes, (a, b) =>
      // a.CarrierSCAC === b.CarrierSCAC &&
      // a.TransitDays === b.TransitDays &&
      // a.ServiceLevel === b.ServiceLevel)
      // // Limit results to 20
      // if (this.allQuotes.length > 20){
      //   this.allQuotes.length = 20
      // }
      if (this.allQuotes.length == 0){
        this.allQuotesPopulated = true
        this.noResults = true;
      }
      if (this.allQuotes.length > 0){
        this.allQuotesPopulated = true
        this.quotesReceived = true
      }

    });
  }

  isFieldsEmpty(moveType, originZip, destinationZip, length, width, height, weight, quantity) {
    if ( moveType == '' || originZip == '' || destinationZip == ''|| length == '' || width  == '' || height == '' || weight == '' || quantity == ''){
      return true
    }
    return false
  }

  private _filter(value) {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.zip.toLowerCase().includes(filterValue));
  }

  zipOCheck(event: any, event2) {
    console.log(event2)
    console.log((event2.relatedTarget))
    console.log("from event:", event)

    if (event2.relatedTarget === null && !(zipcodes.some(el => el.zip === event))) {
      this.oZip = ''
      return;
    }

    if (  (!(zipcodes.some(el => el.zip === event)) && !((event2.relatedTarget).innerHTML).includes('mat-option'))){
      console.log("not in list")
      this.noBueno()
      console.log("reset")
     
    }
    console.log(event2.relatedTarget)
   
    //this.checkedOClick=false
  
  }

  noBueno(){
    this.oZip = ''
  }

  zipDCheck(event: any, event2) {
    console.log(event2)
    console.log((event2.relatedTarget))
    if (event2.relatedTarget === null && !(zipcodes.some(el => el.zip === event))) {
      this.dZip = ''
      return;
    }

    if (  (!(zipcodes.some(el => el.zip === event)) && !((event2.relatedTarget).innerHTML).includes('mat-option'))){
      console.log("not in list")
      this.dZip = ''
      console.log("reset")
     
    }

  }

  

  zipClick(event: any) {
    if (!zipcodes.some(el => el.zip === event.option.value)){
      console.log("not in list")
      this.noBueno()
      console.log("reset")
     
    }

    
  }

  // checkZip() {
  //   if (!this.selectedZip || this.selectedZip !== this.signatureFormGroup.controls['country'].value) {
  //     this.signatureFormGroup.controls['country'].setValue(null);
  //     this.selectedCountry = '';
  //   }
  // }

  ngOnInit() {
    try {
      var token = JSON.parse(localStorage.getItem("key"))
      var userType = JSON.parse(localStorage.getItem("role"))
      if (token == null){
        this.router.navigate(['login']);
      }
      if (userType == "internal-user"){
        this.isAgent = true
      }
      if (userType != "internal-user"){
        this.isAgent = false
      }
    } catch (e) {
      this.router.navigate(['login']);
    }
    this.originFilteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => value.length >= 3 ? this._filter(value): [])
      );

    this.destinationFilteredOptions = this.myDControl.valueChanges
    .pipe(
      startWith(''),
      map(value => value.length >= 3 ? this._filter(value): [])
    );
  }

}
